import { useState, useEffect } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";

import { auth } from "../firebase";

const ProtectedRouteHoc = ({ component: Component, isLoggedIn, ...rest }) => {
  if (isLoggedIn || rest.public || auth.currentUser) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return <Component {...props}></Component>;
        }}
      />
    );
  }
  return <Redirect to={{ pathname: "/" }} />;
};

export default withRouter(ProtectedRouteHoc);
