import { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { AuthContext } from "../index";
import { Link } from "react-router-dom";
import firebase from "../firebase";

import { db } from "../firebase";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  position: relative;
  & .header {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  & a {
    position: absolute;
    display: block;
    height: 17px;
    font-size: 15px;
    text-transform: uppercase;
    right: 50px;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  border: 1px solid #d2d2d2;
  /* padding-bottom: 30px; */
`;

const TableWrapper = styled.table`
  width: 100%;
  min-width: 1200px;
  border-collapse: collapse;
  border-style: hidden;
  thead th {
    position: sticky;
    top: 0;

    background-color: #282c4c;
    color: #fff;
  }
  /* & td,
  th {
    padding: 0 10px;
  } */
  & .team-name {
    min-width: 150px;
    position: sticky;
    left: 0px;
    /* border-collapse: separate; */
  }
  & thead > tr > th {
    &.team-name {
      z-index: 9999;
    }
    border-right: 1px solid #fff;
  }

  /* & th:nth-child(2) {
    border-left: 1px solid #fff;
  } */

  & td.team-name {
    z-index: 999;
  }
  & tr,
  tbody,
  thead {
    width: 100%;
    & td,
    th {
      /* width: 50%; */
      text-align: center;
      padding: 5px 10px;
      /* border: 1px solid #d2d2d2; */
    }
    /* & tr {
      border: 1px solid #d2d2d2;
    } */
  }
  thead th {
    text-transform: uppercase;
  }
  tbody > tr {
    height: 50px;
  }
  tbody > tr:nth-child(2n) {
    & td,
    tr {
      border: 1px solid #fff;
      background-color: #cce3fd;
      color: #000;
    }
    & .team-name {
      background-color: #7dbaff;
    }
    & .inside-table {
      padding: 0;
      border-collapse: collapse;
      & td {
        border: 1px solid #fff;
      }

      & table {
        height: 100%;
        border: none;
        border-collapse: collapse;
      }

      table tr {
        border: none;
      }
      table tr td {
        border: 1px solid #fff;
      }
      table tr:first-child td {
        border-top: 0;
      }
      table tr td:first-child {
        border-left: 0;
      }
      table tr:last-child td {
        border-bottom: 0;
      }
      table tr td:last-child {
        border-right: 0;
      }
    }
  }
  tbody > tr:nth-child(2n + 1) {
    & td,
    tr {
      border: 1px solid #fff;
      background-color: #5660aa;
      color: #fff;
    }
    & .team-name {
      background-color: #2c3baa;
    }

    & .inside-table {
      padding: 0;
      height: 100%;
      border-collapse: collapse;
      & td {
        border: 1px solid #fff;
      }

      & table {
        height: 100%;
        border: none;
        border-collapse: collapse;
      }

      table tr {
        border: none;
      }
      table tr td {
        border: 1px solid #fff;
      }
      table tr:first-child td {
        border-top: 0;
      }
      table tr td:first-child {
        border-left: 0;
      }
      table tr:last-child td {
        border-bottom: 0;
      }
      table tr td:last-child {
        border-right: 0;
      }
    }
  }
`;

const Code = () => {
  const [codes, setCodes] = useState([]);

  const Auth = useContext(AuthContext);

  useEffect(() => {
    let unsubscribe = db.collection("teams").onSnapshot((snapshots) => {
      let newCodes = [];
      snapshots.forEach((doc) => {
        let code = { id: doc.id, ...doc.data() };
        if (code.archived) {
          newCodes.push(code);
        }
      });
      setCodes([]);
      setCodes(newCodes);
    });
    return unsubscribe;
  }, []);

  const handleNewCodeClick = () => {
    let code = Math.random().toString(16).slice(9);
    while (codes.findIndex((item) => item.code === code) !== -1) {
      code = Math.random().toString(16).slice(9);
    }
    db.collection("teams").doc(code).set({
      code: code,
      used: false,
      createdAt: Date.now(),
      player: null,
      createdBy: Auth.user.email,
    });
    //   .then(() => updateCodes());
  };

  const handleDeleteCode = (id) => {
    let teamRef = db.collection("teams").doc(id);

    db.runTransaction(function (transaction) {
      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(teamRef).then(function (doc) {
        if (!doc.exists) {
          throw "Document does not exist!";
        }
        transaction.update(teamRef, { archived: false });
      });
    })
      .then(function () {
        console.log("Transaction successfully committed!");
      })
      .catch((err) => alert("Une erreur est survenue veuillez réessayer"));
  };

  const getTime = (time) => {
    if (time.getDate) {
      return (
        time &&
        `${time.getDate()}/${time.getMonth()}/${time.getFullYear()} ${time.getHours()}:${
          time.getMinutes() > 10 ? time.getMinutes() : `0${time.getMinutes()}`
        }`
      );
    } else {
      return time / 60;
    }
  };

  const calcTime = (data, i) => {
    let timeElapsed = 0;
    if (data.enigmTimes[i]) {
      if (i === 0) {
        timeElapsed = data.enigmTimes[i] - data.startedAt;
      } else {
        timeElapsed = data.enigmTimes[i] - data.enigmTimes[i - 1];
      }
      return convertToTime(timeElapsed);
    } else {
      return "not yet";
    }
  };

  const convertToTime = (t) => {
    let totalSeconds = t / 1000;
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = Math.round(totalSeconds % 60);
    if (seconds >= 30) {
      minutes += 1;
    }
    return { minutes: minutes, seconds: seconds };
  };

  let addEmptyRow = (n) => {
    const arr = [];
    for (let i = 0; i < n; i++) {
      arr.push(
        <tr>
          <td className="team-name"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    }
    return arr;
  };

  return (
    <Wrapper>
      <div className="header">
        <h1 style={{ margin: "10px 0", fontSize: 20, fontWeight: 800 }}>
          {" "}
          ARCHIVES
        </h1>

        <Link to="/code">Récentes</Link>
      </div>
      <TableContainer>
        <TableWrapper>
          <thead style={{ position: "sticky" }}>
            <tr style={{}}>
              <th className="team-name">equipe</th>
              <th>ville</th>
              <th>commencé</th>
              <th>
                <tr>
                  <th colSpan="2">Enigm1</th>
                </tr>
                <tr>
                  <th colSpan="2">Temps</th>
                </tr>
                <tr>
                  <th>indice1</th>
                  <th>indice2</th>
                </tr>
              </th>
              <th>
                <tr>
                  <th colSpan="2">Enigm2</th>
                </tr>
                <tr>
                  <th colSpan="2">Temps</th>
                </tr>
                <tr>
                  <th>indice1</th>
                  <th>indice2</th>
                </tr>
              </th>
              <th>
                <tr>
                  <th colSpan="2">Enigm3</th>
                </tr>
                <tr>
                  <th colSpan="2">Temps</th>
                </tr>
                <tr>
                  <th>indice1</th>
                  <th>indice2</th>
                </tr>
              </th>
              <th>
                <tr>
                  <th colSpan="2">Enigm4</th>
                </tr>
                <tr>
                  <th colSpan="2">Temps</th>
                </tr>
                <tr>
                  <th>indice1</th>
                  <th>indice2</th>
                </tr>
              </th>
              <th>
                <tr>
                  <th colSpan="2">Enigm5</th>
                </tr>
                <tr>
                  <th colSpan="2">Temps</th>
                </tr>
                <tr>
                  <th>indice1</th>
                  <th>indice2</th>
                </tr>
              </th>
              <th>
                <tr>
                  <th colSpan="2">Enigm6</th>
                </tr>
                <tr>
                  <th colSpan="2">Temps</th>
                </tr>
                <tr>
                  <th>indice1</th>
                  <th>indice2</th>
                </tr>
              </th>
              <th>
                <tr>
                  <th colSpan="2">Enigm7</th>
                </tr>
                <tr>
                  <th colSpan="2">Temps</th>
                </tr>
                <tr>
                  <th>indice1</th>
                  <th>indice2</th>
                </tr>
              </th>
              <th>temps total</th>
              <th>annule archive</th>
            </tr>
          </thead>
          <tbody>
            {codes
              .sort((a, b) => a.startedAt - b.startedAt)
              .map((item, index) => {
                let startedAt = new Date(item.startedAt);
                const arrayOf6 = ["", "", "", "", "", "", ""];
                let totalTime = { minutes: 0, seconds: 0, toText: "0:00" };
                let enigmTimes = arrayOf6.map((t, i) => {
                  if (item.enigmTimes[i]) {
                    const time = calcTime(item, i);
                    let toText = `${time.minutes}:${
                      time.seconds > 10 ? time.seconds : "0" + time.seconds
                    }`;
                    if (totalTime.seconds + time.seconds > 59) {
                      totalTime.minutes += time.minutes + 1;
                      totalTime.seconds = totalTime.seconds + time.seconds - 60;
                    } else {
                      totalTime.minutes += time.minutes;
                      totalTime.seconds = totalTime.seconds + time.seconds;
                    }
                    totalTime.toText = `${totalTime.minutes}:${
                      totalTime.seconds > 10
                        ? totalTime.seconds
                        : "0" + totalTime.seconds
                    }`;
                    return toText;
                  } else {
                    return "pas répondu";
                  }
                });
                return (
                  <tr>
                    <td className="team-name">{item.teamName}</td>
                    <td>{item.location}</td>
                    {/* <td>{item.points}</td> */}
                    <td>{getTime(startedAt)}</td>
                    {arrayOf6.map((n, i) => (
                      <td className="inside-table">
                        <table style={{ width: "100%" }}>
                          <tr>
                            <td colSpan="2">{enigmTimes[i]}</td>
                          </tr>
                          <tr>
                            <td>{item.indices[i]["1"]}</td>
                            <td>{item.indices[i]["2"]}</td>
                          </tr>
                        </table>
                      </td>
                    ))}
                    <td>{totalTime.toText}</td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDeleteCode(item.id)}
                    >
                      ✅
                    </td>
                  </tr>
                );
              })}
            {addEmptyRow(20)}
          </tbody>
        </TableWrapper>
      </TableContainer>
    </Wrapper>
  );
};

export default Code;
