import React, { useState, useEffect, useContext } from "react";
import "./App.css";
import styled from "styled-components";
import { AuthContext } from "./index";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  withRouter,
} from "react-router-dom";
import { auth } from "./firebase";

import SignIn from "./components/SignIn";
import Code from "./components/Code";
import Old from "./components/Old";
import ProtectedRoute from "./components/ProtectedRoute";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const App = ({ isLoggedIn }) => {
  const Auth = useContext(AuthContext);
  // useEffect(() => {
  //   let interval = setInterval(() => {
  //     if (auth.currentUser && Auth.isLoggedIn) {
  //       // let user = auth.currentUser;
  //       // let user = await db.collection("users").doc(res.user.uid).get();
  //       // Auth.setUser({ id: user.id, ...user.data() });
  //       console.log("logged");
  //       Auth.setLoggedIn(true);
  //       // Auth.setUser({ id: user.id, ...user.data() });
  //       // history.push("/code");
  //     }
  //   }, 10);
  //   return interval;
  // }, []);

  return (
    <Wrapper>
      {/* Is logged in? {JSON.stringify(isLoggedIn)} */}
      <Router>
        <Switch>
          <Route path="/" exact={true} component={() => <SignIn></SignIn>} />
          <ProtectedRoute
            isLoggedIn={isLoggedIn}
            path="/code"
            component={Code}
          />
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/old" component={Old} />
        </Switch>
      </Router>
    </Wrapper>
  );
};

export default App;
