import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Main from "./App";
import reportWebVitals from "./reportWebVitals";

export const AuthContext = React.createContext({
  isLogIn: false,
});

const App = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  return (
    <AuthContext.Provider value={{ isLoggedIn, setLoggedIn, user, setUser }}>
      <Main isLoggedIn={isLoggedIn}></Main>
    </AuthContext.Provider>
  );
};
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
