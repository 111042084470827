import styled from "styled-components";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../index";
import { auth, login, db } from "../firebase";
import firebase from "firebase";
import { withRouter } from "react-router-dom";

const SignInWrapper = styled.div`
  width: 300px;
  & > h2 {
    text-align: center;
  }
  & > input  {
    width: calc(100% - 25px);
    padding: 10px;
    text-align: center;
    font-size: 16px;
    margin: 20px 0 0 0;
  }
  & > button {
    background-color: #0095ff;
    border-radius: 2px;
    color: #fff;
    border: none;
    width: 100%;
    padding: 10px;
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 900;
  }
`;

const Error = styled.div`
  color: #f00;
`;

const SignIn = (props) => {
  const { history } = props;
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const Auth = useContext(AuthContext);

  const handleSubmitClick = (e) => {
    e.preventDefault();
    login(email, password)
      .then(async (res) => {
        if (res.user) {
          Auth.setLoggedIn(true);
          let user = await db.collection("users").doc(res.user.uid).get();
          Auth.setUser({ id: user.id, ...user.data() });
          history.push("/code");
        }
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  const handleKeyPress = (e) => {
    if (e.charCode == 13) {
      handleSubmitClick(e);
    }
  };

  return (
    <SignInWrapper>
      <h2>LOGIN</h2>
      <input
        type="text"
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
        onKeyPress={handleKeyPress}
      />
      <input
        type="password"
        placeholder="Password"
        onChange={(e) => setPassword(e.target.value)}
        onKeyPress={handleKeyPress}
      />
      {error !== "" && <Error>{error}</Error>}
      <button onClick={handleSubmitClick}>valider</button>
    </SignInWrapper>
  );
};

export default withRouter(SignIn);
